<template>
  <div class="login-w">
    <div class="main-c">
      <div class="c-w">
        <div class="welcome-c"></div>
        <div class="login_form-w">
          <div class="logo_icon">
            <img src="@/assets/image/login-logo.png" alt="" />
          </div>
          <div class="form-w">
            <form action="" class="form">
              <div class="form_item">
                <div class="input_icon">
                  <img src="@/assets/image/login-acc.png" alt="" />
                </div>
                <input
                  type="text"
                  id="username"
                  placeholder="请输入工号"
                  v-model="dynamicValidateForm.name"
									maxlength="8"
                />
              </div>
              <div class="form_item">
                <div class="input_icon">
                  <img src="@/assets/image/login-psd.png" alt="" />
                </div>
								<input
								  type="password"
								  id="password"
								  placeholder="请输入密码"
								  v-model="dynamicValidateForm.pwd"
									autocomplete="off"
								/>
              </div>
              <div class="remember_psd">
								<el-checkbox v-model="dynamicValidateForm.flag" :true-label="1" :false-label="0">记住密码</el-checkbox>
              </div>
              <el-button
                class="login_btn"
								@click="submitForm('dynamicValidateForm')"
								@keyup.enter="submitForm"
                >登录</el-button>
            </form>
          </div>
        </div>
      </div>
      <div class="footer-w">
        Copyright©2018-2020 德立教育集团 版权所有 粤ICP备11085236号-1
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Cookies from "js-cookie";
import { login_login } from "@/request/api/public";
import { myRequest } from "@/request/index";
import { setSession, setLocal, getLocal, messageTip } from "@/utils/util";

export default {
  inject: ["reload"],
  name: "login",
  data() {
    let validateName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入用户名"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      dynamicValidateForm: {
        name: "",
        pwd: "",
      },
			flag: '',
      rules: {
        name: [{ validator: validateName, trigger: "blur" }],
        pwd: [{ validator: validatePass, trigger: "blur" }],
      },
    };
  },
	created() {
		console.log(this.$store.state.login, '这个是什么东西啊---', this.$store.state.login.cooperation, '48啊哈')
		let name = window.atob(this.getCookie("name"))
		let pwd = window.atob(this.getCookie("pwd"))
		if(name) {
			this.dynamicValidateForm.name = name
			this.dynamicValidateForm.pwd = pwd
			this.dynamicValidateForm.flag = 1
		}
	},
  methods: {
    ...mapActions("aside", ["mainNav"]),
    //登录 click
    submitForm(formName) {
      if (!this.dynamicValidateForm.name || !this.dynamicValidateForm.pwd) return messageTip({ type: "warning", message: "账户不存在或密码错误" });
			this.login();
    },
    //登录 api
    login() {
      myRequest({
        url: login_login,
        data: this.dynamicValidateForm,
      }).then((res) => {
        if (res.data.code === "200") {
          this.$store.state.tab.editableTabs = [];
          this.$store.state.login.userinfo = res.data.data.user;
          setSession("user", res.data.data.user);
          setSession("token", res.data.data.token);
					sessionStorage.setItem('name', this.dynamicValidateForm.name);
					sessionStorage.setItem('pwd', this.dynamicValidateForm.pwd);
					this.rememberUser();
					this.$router.push("/");
          this.mainNav(32204); //二级菜单默认为 - 业务管理
        }
      });
    },
		// 记住用户
		rememberUser() {
		  if( this.dynamicValidateForm.flag) {
				this.setCookie("name", window.btoa(this.dynamicValidateForm.name), 7)
				this.setCookie("pwd", window.btoa(this.dynamicValidateForm.pwd), 7)
				this.setCookie("flag", this.dynamicValidateForm.flag, 7)
			} else {
				this.setCookie("name", "", -1)
				this.setCookie("pwd", "", -1)
				this.setCookie("flag", this.dynamicValidateForm.flag, 7)
			}
		},
		//设置cookie
		setCookie(cName, value, expiredays) {
		    let exdate = new Date(); //获取时间
		    exdate.setDate(exdate.getDate() + expiredays)
				document.cookie = cName + "=" + value +
					((expiredays == null) ? '' : ';expires=' + exdate.toGMTString())
		    
		},
		// 读取cookie
		getCookie(key) {
		    let that = this;
		    if (document.cookie.length > 0) {
					var start = document.cookie.indexOf(key + '=')
					if(start !== -1) {
						start = start + key.length + 1
						var end = document.cookie.indexOf(';', start)
						if (end === -1) end = document.cookie.length
						return unescape(document.cookie.substring(start, end))
					}
		    }
				return ''
		},

		// 点击回车键登录
		keyDown(e) {
		  // 回车则执行登录方法 enter键的ASCII是13
		  if (e.keyCode === 13) {
		    this.login(); // 定义的登录方法
		  }
		},
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
	mounted() {
	   // 绑定监听事件
	   window.addEventListener("keydown", this.keyDown);
	 },
	 destroyed() {
	   // 销毁事件
	   window.removeEventListener("keydown", this.keyDown, false);
	 },
		beforeRouteLeave(to, from, next){ 
			to.meta.keepAlive = false
			next()
	}
};
</script>

<style lang="scss" scoped>
.login-w {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../assets/image/login-bg.jpg") 100% 100%/100% 100%
    no-repeat;
  height: 100%;
}
.main-c {
  width: 1000px;
  height: 480px;
  .c-w {
    display: flex;
    align-items: center;
    height: 100%;
    .welcome-c {
      width: 550px;
      height: 100%;
      background: url("../../assets/image/login-welcome2.png") center
        center/cover no-repeat;
    }
  }
  .footer-w {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    font-size: 12px;
    color: #e5edf4;
  }
}
.login_form-w {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
  height: 100%;
  padding: 60px 0 0 0;
  box-sizing: border-box;
  background-color: #fff;
  .logo_icon {
    text-align: center;
    & > img {
      width: 80%;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    margin: 40px 0 0 0;
    width: 250px;

    .login_btn {
      margin: 60px 0 0 0;
      padding: 12px 0 !important;
      text-align: center;
      background-color: #00a2ea;
      // background-color: #d62a17;
      border-radius: 6px !important;
      color: #fff;
      cursor: pointer;
      font-size: 16px;
    }
    .remember_psd {
      display: flex;
      align-items: center;
      margin: 10px 0 0 0;
      .label {
        margin: 0 0 0 10px;
        font-size: 12px;
        color: #999;
      }
    }
    .form_item {
      position: relative;
      width: 100%;
      & > input {
        width: 80%;
        padding: 12px 0 12px 38px;
        margin: 0 0 20px 0;
        border: 1px solid #eee;
        border-radius: 4px;
        &:focus {
          outline: none; //这个可以把原来的线去掉
          border: 1px solid rgba($color: #00a2ea, $alpha: 0.5);
          box-shadow: -1px 0px 10px 4px rgba($color: #00a2ea, $alpha: 0.2);
        }
        &::-webkit-input-placeholder {
          color: #999;
        }
      }
      .input_icon {
        position: absolute;
        display: flex;
        align-items: center;
        top: 10px;
        left: 12px;
      }
    }
  }
}
</style>
